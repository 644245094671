* {
    box-sizing: border-box;
}

html, body, #root {
    width: 100vw;
    height: 100vh;
}

#root {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 450px;
    grid-template-rows: minmax(0, 1fr) 200px;
    grid-template-areas:
        "setup dictionary"
        "points points";
    grid-gap: 10px;
    padding: 10px;
}

#root > .MuiPaper-root:nth-child(1) {
    grid-area: setup;
    overflow: auto;
}

#root > .MuiPaper-root:nth-child(2) {
    grid-area: points;
}

#root > .MuiPaper-root:nth-child(3) {
    grid-area: dictionary;
}

#root > .MuiPaper-root {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

#root > .MuiPaper-root > div:nth-child(2) {
    flex: 1 0 auto;
}

hr {
    border-bottom: none;
}
